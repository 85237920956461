import React from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
const cookies = new Cookies();

function WelcomePage(props) {
  function handleSignIn() {
    //reload the page to force a sign-in
    window.location.reload();
  }
  function handleLogOut() {
    cookies.remove('token');
    window.location.href = 'https://accounts.google.com/logout';
  }
  return (
    <div className="centered-container fade-in">
      <div className="centered-box">
        <h1>CAENView</h1>
        <h2>Managing Classrooms Since 2012</h2>
        {props.errorObj.message && 
          <h3>{props.errorObj.message}</h3>
        }
        <p>Please sign in with your <strong>@umich</strong> email</p>
        {(props.errorObj.option === 'logout')
          ? <div className="button col-xs-12" onClick={handleLogOut}>Log out</div>
          : <div className="button col-xs-12" onClick={handleSignIn}>Sign in</div>}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({errorObj: state.errorObj});
export default connect(mapStateToProps)(WelcomePage)