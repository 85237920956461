import React, { useState } from 'react'
import ConfirmBox from '../Reusable/ConfirmBox'
import { connect } from 'react-redux'
import { sendCommand } from '../../helperFunctions/actions'


const TURN_OFF = 0, RESTART = 1, SOURCE_SELECT = 2; // enums for confirm boxes
function IOSRoomControl( { display_data, sources, room, ...props } ) {
  const [selectedDisplayId, setSelectedDisplayId] = useState(0);
  const [nextCommand, setNextCommand] = useState("");  
  const [confirmingCommand, setConfirmingCommand] = useState(null);

  function handleSourceSelect(newSourceId) {
    const cmd = { command: { source: newSourceId, id: selectedDisplayId } }
    props.sendCommand( cmd, {id: room.id} );
    setConfirmingCommand(null);
  }

  function sendCommand() {
    props.sendCommand( nextCommand, {id: room.id} );
    setConfirmingCommand(null);
  }


  function handleChangeSource(display) {
    setSelectedDisplayId(display.id);
    setConfirmingCommand(SOURCE_SELECT);
  }

  function handleRestartSelect() {
    setNextCommand( {restart: 1} );
    setConfirmingCommand(RESTART)
  }

  function handleTurnOff(display) {
    setNextCommand( {command: { power: !display.power, id: display.id }} );
    setConfirmingCommand(TURN_OFF);
  }

  const buttonClassName = room.connected ? "button" : "button-disabled";

  return (
    <div>
      <div className={room.connected ? "room-box" : "room-box box-disabled"}>
          {Array.isArray(display_data) && display_data.map( display => (
            <div className="row box-contents" key={display.id}>
              <div className="col-xs-4 center-xs" style={{display: 'flex', alignItems:'center'}} >
                <p>
                  { display.type + ' ' + display.id }
                </p>
              </div>
              <div className="col-xs-8">
                {display.power && <div className={buttonClassName} id={"source-change-button" + display.id} onClick={() => handleChangeSource(display)}>
                  Change Source
                </div>}
                {display.power && <div className={buttonClassName} id={"turn-off-button" + display.id} onClick={() => handleTurnOff(display)}>
                  Turn Off
                </div>}
                {!display.power && <div className={buttonClassName} id={"source-change-button" + display.id} onClick={() => handleChangeSource(display)}>
                  Turn On + Set Source
                </div>}
              </div>
            </div> 
          ))}
        <div className="row box-contents">
          <div className="col-xs-12">
            <div className={`${buttonClassName} redbg col-xs-12 center-xs`} id="restart-button" onClick={handleRestartSelect}>
              Restart App
            </div>
          </div>
        </div>
      </div>

          {confirmingCommand === RESTART && <ConfirmBox onConfirm={sendCommand} onCancel={() => setConfirmingCommand(null)} targetEl={document.getElementById("restart-button")}/>}
          {confirmingCommand === TURN_OFF && <ConfirmBox onConfirm={sendCommand} onCancel={() => setConfirmingCommand(null)} targetEl={document.getElementById("turn-off-button" + selectedDisplayId)}/>}
      {(confirmingCommand === SOURCE_SELECT) && 
      <ConfirmBox noButton onCancel={() => setConfirmingCommand(null)} targetEl={document.getElementById("source-change-button" + selectedDisplayId)}>
        <>
          {Object.values(sources).map(source => (
            <div className="row box-contents col-xs-12" key={source.id}>
              <div className="col-xs-12">
                <div className="button" onClick={() => {handleSourceSelect(source.id)}}>
                  {source.title}
                </div>
              </div>
            </div>
          ))}
        </>
      </ConfirmBox>}
    </div>
  )
}

const mapActionsToProps = { sendCommand }
export default connect(null, mapActionsToProps)(IOSRoomControl)