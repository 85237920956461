import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatDate from '../../helperFunctions/formatDate'

class Roomlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      log_events: [],
      logsToRequest: 10,
      hash: "",
      moreLogs: true,
      moreLogsMessage: "See More Logs",
      newLogPin: 0,
      newLogText: ""
    }

    this.getInitLogInfo = this.getInitLogInfo.bind(this);
    this.getMoreLogInfo = this.getMoreLogInfo.bind(this);
    this.handleSeeMore = this.handleSeeMore.bind(this);
    this.toggleNewLogPin = this.toggleNewLogPin.bind(this);
    this.handleNewLogChange = this.handleNewLogChange.bind(this);
    this.addNewLog = this.addNewLog.bind(this);
    this.onStatus = this.onStatus.bind(this);
    this.getInitLogInfo(5); //start off with some log info
  }

  componentDidMount() {
    window.g_socket.on('logs', (log_data) => {
      let newData = {}; //we'll apply this to state later
      if((this.state.hash === "") || (this.state.hash !== log_data.hash)) {
        newData.hash = log_data.hash;
        newData.log_events = log_data.logs;
        //if we've (assumedly) reached the end of the logs
        if(newData.log_events.length === log_data.logChunkSize) 
        {
          newData.moreLogs = true;
          newData.moreLogsMessage = "See More Logs";
        } 
        else 
        {
          newData.moreLogs = false;
          newData.moreLogsMessage = "End of Logs";
        }
      }
      this.setState({...newData})
    })
    window.g_socket.on('status', this.onStatus);
    window.g_socket.on('update', this.onStatus);
  }
  componentWillUnmount() {
    //clean up listeners
    window.g_socket.removeAllListeners('logs');
    window.g_socket.removeListener('status', this.onStatus)
    window.g_socket.removeListener('update', this.onStatus)
  }

  getInitLogInfo(tries) {
    if(this.state.log_events.length > 0) return; //already have logs
    window.g_socket.emit('logrequest', {"room_id": this.props.room.id, "amount": 10});
    if(tries > 0) {
      setTimeout(() => this.getInitLogInfo(tries - 1), 500); 
    }
  }

  onStatus(newRoom) {
    if(newRoom.id.toString() === this.props.room.id) {
      this.getMoreLogInfo(0)
    }
  }

  getMoreLogInfo(howManyMore) {
    const logsToRequest = this.state.logsToRequest + howManyMore;
    window.g_socket.emit('logrequest', {"room_id": this.props.room.id, "amount": logsToRequest});
    this.setState({logsToRequest})
  }

  handleSeeMore() {
    if(this.state.moreLogs) {
      this.getMoreLogInfo(10);
    }
  }
  
  updateNewLog(obj) {
    let data;
    if(obj.target) {
      data = {text: obj.target.value};     
    } else data = obj;
    this.setState(prev => ({
      newLog: { //return the previous newLog data, plus the new data
        ...prev.newLog,
        ...data
      }
    }))
  }

  pinLog(index) {
    let log = this.state.log_events[index];
    // toggle pinned status
    log.pin = log.pin ? 0:1; 
    // send it to the server
    window.g_socket.emit('pin', this.props.room.id, log.id, log.pin);
    // until we get an update, we set our state to the new value
    this.setState(prev => {
      const { log_events } = prev;
      log_events[index] = log;
      return { log_events }
    })
    this.setState(prev => ({
      log_events: prev.log_events.sort(this.logComparison)
    }))
    // FIXME that's a hack - it's how the old CAENView does it, but it pretends it updated before it learns that it did
  }

  /* ---- NEW LOG ---- */
  toggleNewLogPin() {
    this.setState(prev => ({newLogPin: prev.newLogPin ? 0:1}))
  }
  handleNewLogChange(e) {
    this.setState({newLogText:e.target.value})
  }
  addNewLog() {
    // should look like this: Andrew K (adkimmy) : test - May 11, 2020: 4:06 PM
    if(this.state.newLogText !== "") {
      let output = this.props.nickname + ' (' + this.props.uniqname + ') : ' + this.state.newLogText;
      window.g_socket.emit('add-to-log', this.props.room.id, output, this.state.newLogPin);
      this.setState({newLogText:"", newLogPin: 0})
    }
    this.getMoreLogInfo(0); //update logs
  }

  logComparison(a,b) {
    if(a.pin) {
      if(b.pin) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      } else {
        return -1;
      }
    } else {
      if(b.pin) {
        return 1;
      } else {
        return new Date(b.timestamp) - new Date(a.timestamp);
      }
    }
  }

  render() {
    const { log_events, newLogPin, newLogText } = this.state;
    let loglist;
    if (log_events.length > 0) {
      if(log_events[0].message === "No Log Messages" && log_events[0].timestamp === "1969-12-31T19:00:00-05:00") {
        //no logs, display only the message (otherwise it claims the log is from 1969)
        loglist = <div className="row box-contents"><div className="col-xs-12"><p>&emsp;&emsp;No Log Messages</p></div></div>
      } else {
        //map the logs to rows with pins
        loglist = log_events.map((log,index) => (
          <div key={log.id} className="row box-contents">
            <div className="col-xs-12">
              <i className={"fas fa-thumbtack " + (log.pin ? 'pinned' : 'grey')} onClick={() => this.pinLog(index)}></i>
              <p> {log.message} - {formatDate(log.timestamp)}</p>
            </div>
          </div>
        ));
      }
    }
    else {
      loglist = (<div> loading logs...</div>);
    }
    return (
      <div className="log">
        <div className="room-box wide-box">
          {/*Room Log header*/}
          <div className="row center-xs box-title greybg">
            <div className="col-xs-12">
            <h4>Room Log (1 - {this.state.logsToRequest})</h4>
            </div>
          </div>

          {/*add to log*/}
          <div className="row box-contents">
            <div className="col-xs-12 testing">
              <i className={"fas fa-thumbtack " + (newLogPin ? 'pinned' : 'grey')} onClick={this.toggleNewLogPin}></i>
              <input className="text-input" type="text" placeholder="Enter new log text..." value={newLogText} onChange={this.handleNewLogChange}/>
              <div className="button log-button" onClick={this.addNewLog}>Add to log</div>
            </div>
          </div>

          {/*Logs*/}
          {loglist}

          {/*SEE More Logs*/}
          <div className="row box-contents">
            <div className="col-xs-12 center-xs">
              <div className={"button" + (this.state.moreLogs ? '' : 'not')} onClick={this.handleSeeMore}>{this.state.moreLogsMessage}</div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    uniqname: state.uniqname,
    nickname: state.nickname,
  }
}
export default withRouter(connect(mapStateToProps)(Roomlog))