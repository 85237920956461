import React from 'react';

import { connect } from 'react-redux';

// window with a button to alert the user of all professors in the current calendar view
// (if it's week view, that week. If it's a month, everyone's emails from that month.)
function RoomDetails(props){
  function handleClick() {
    window.prompt('Copy Email of Prof For the Week', props.profEmails.join(', '));
  }
  return (
    <div className="room-details">
      <div className="room-box">
        <div className="row center-xs box-title greybg">
          <div className="col-xs-12">
            <h4>Room Details</h4>
          </div>
        </div>
        <div className="row box-contents">
          <div className="col-xs-12 center-xs">
            <div className="button" onClick={handleClick}>List Addresses for Week&rsquo;s Instructors</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { profEmails: state.profEmails }
}

export default connect(mapStateToProps)(RoomDetails)