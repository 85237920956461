import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MenuKey from './MenuKey'
import MenuSettings from './MenuSettings'
import NoticeBar from './NoticeBar'
import { withRouter } from 'react-router-dom'
import { setReduxState, updateShownRooms, toggleCookie } from '../../helperFunctions/actions'
import RefreshIcon from './RefreshIcon'

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "none",
      popupHover: false,
      justClickedIcon: false,
      showMobileSettings: false
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  
    window.onclick = (function() {
      if(this.state.justClickedIcon) {
        this.setState({justClickedIcon: false})
      } else {
        if(!this.state.popupHover && this.state.selected !== "none") {
          //if mouse is not over popup && a popup is showing,
          this.setState({selected: "none"}); //user clicked off popup
        }
      }
    }).bind(this);
  }

  handleSearch(event) {
    event.preventDefault();
    if(this.props.location.pathname !== '/') this.props.history.push('/');
    this.props.setReduxState( {searchQuery: event.target.value} );
    this.props.updateShownRooms(); // filter which rooms should be shown
  }

  togglePopup(popupName) {
    if(this.state.selected === popupName) {
      this.setState({selected: "none"})
    } else {
      this.setState({selected: popupName})
    }
    this.setState({justClickedIcon: true})
  }


  renderPopups() {
    if(this.state.selected) {
      let popup;
      switch(this.state.selected) {
        case "info": popup = <MenuKey />; break;
        case "settings": popup = <MenuSettings togglePopup={this.togglePopup}/>; break;
        default: popup = null;
      }

      return <div onMouseEnter = {() => this.setState({popupHover:true})} onMouseLeave =  {() => this.setState({popupHover: false})}>
        {popup}
      </div>
    }
  }

  render() {
    return (
      <div>
        {this.renderPopups()}
        <nav className="navbar" role="navigation">

          <div className="navbar-header">
            <h1><Link className="navbar-brand" to="/">CAENView</Link></h1>
          </div>
              
          <div className="navbar-search">	
            <input className="navbar-input" id="menu-search" type="text" placeholder="Search for rooms..." onChange={this.handleSearch} autoComplete="off"/>
            <span className="search-icon" onClick={() => document.getElementById("menu-search").focus()}> {/*clicking on search icon focuses searchbox*/}
              <i className="fas fa-search"></i>
            </span>
          </div>
            <div className="navbar-burger">
              <div className="navbar-icon" onClick={() => this.setState(prev => ({showMobileSettings:!prev.showMobileSettings}))}>
                <i className="fa fa-bars"></i>
              </div>
            </div>

            <div className={this.state.showMobileSettings ? "navbar-icons expanded":"navbar-icons"}>
              {/* REFRESH ICON */}
              {this.props.showImages &&
                <span className="navbar-icon" onClick={() => this.props.toggleCookie('refreshImages')}>
                  {this.props.refreshImages
                  ? <RefreshIcon spin={this.props.spinRefreshIcon} data-tip="Turn Off Camera Refreshing" color="var(--light-blue)"/>
                  : <RefreshIcon data-tip="Turn On Camera Refreshing" color="#FFFFFF"/>
                  }
                </span>
              }
              {/* IMAGE ICON */}
              <span className="navbar-icon" onClick={() => this.props.toggleCookie('showImages')}> 
                {this.props.showImages
                  ? <i className="fas fa-image" data-tip="Hide Room Cameras" style={{color:'var(--light-blue)'}}></i>
                  : <i className="fas fa-image" data-tip="Show Room Cameras"></i>
                }
              </span>
              {/* SOUND ICON */}
              <span className="navbar-icon" onClick={() => this.props.toggleCookie('soundOn')}> 
                {this.props.soundOn 
                  ? <i className="fas fa-volume-up" data-tip="Turn Sound Off"></i>
                  : <i className="fas fa-volume-mute" data-tip="Turn Sound On"></i>
                }
              </span>
              {/* CALENDAR ICON */}
              <span className="navbar-icon" onClick={() => this.props.history.push('/calendargrid')}> 
                <i className="fas fa-calendar-alt"></i>
              </span>
              {/* MENU KEY ICON */}
              <span className="navbar-icon" onClick={() => this.togglePopup("info")}> 
                <i className="fas fa-info-circle"></i>
              </span>
              {/* SETTINGS ICON */}
              <span className="navbar-icon" onClick={() => this.togglePopup("settings")}> 
                <i className="fas fa-cog"></i>
              </span>
              <div className="navbar-user" onClick={() => this.togglePopup("settings")}>	
                <h2> <span>{this.props.nickname}</span> </h2>
                <span className="uniqname">({this.props.uniqname})</span>
              </div>
            </div>
        </nav>
        <NoticeBar />
      </div>
    );
  }
}


// let the component listen to just a few props that dierctly affect it 
function mapStateToProps(state) { 
  return {
    nickname: state.nickname,
    uniqname: state.uniqname,
    soundOn: state.soundOn,
    showImages: state.showImages,
    refreshImages: state.refreshImages,
    activeFilters: state.activeFilters,
    spinRefreshIcon: state.spinRefreshIcon
  }
}
//give component these functions to modify Redux store
const mapDispatchToActions = {setReduxState, updateShownRooms, toggleCookie};
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(Topbar))