import React from 'react'
import { useTable, useSortBy } from 'react-table'
import './MicReportStyles.css'


function Table({ columns, data, name }) {
  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
      columns,
      data
      },
      useSortBy
    )

    return (
      <table className={name.toLowerCase().replace(' ', '-')} {...getTableProps()}>
        <caption>{name}</caption>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ?  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sort-down" viewBox="0 0 16 16">
                            <path d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                          </svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sort-up" viewBox="0 0 16 16">
                            <path d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                          </svg>
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
}

function MicTable({ mics, mic_cols }) {
  // Wrapper hook required by react-table
  const columns = React.useMemo(() => mic_cols, []);

  const data = React.useMemo(() => mics, []);

  return <Table columns={columns} data={data} name={'Mic Report'}/>
}

class MicReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mics: [],
      mic_cols: [],
      data_recived: false
    }
  }

  componentDidMount() {
    window.g_socket.emit('get_rooms_mic_data');

    window.g_socket.on('rooms_mic_data', (rooms_mic_data) => {
        let mics = [];
        let mic_cols = [];
        let has_cols = false;
        rooms_mic_data.forEach((room) => {
          let akg_base_data = JSON.parse(room.akg_base_data);

          if (akg_base_data.Device && akg_base_data.Slots) {
            akg_base_data.Slots.forEach((slot) => {
              mics.push({
                room_name: <strong>{room.room_name}</strong>,
                mic_position: slot.slot_position.readable,
                state: slot.batt_state.readable,
                charge: slot.batt_charge.readable,
                current_capacity: slot.batt_current_capacity.readable,
                current_capacity_max: slot.batt_current_capacity_max.readable,
                battery_capacity: slot.batt_capacity_max.readable,
                health: slot.batt_health.readable,
                cycles: slot.batt_cycle.readable,
                error: slot.batt_error.readable,
                temperature: slot.batt_temp_f.readable,
                time_to_full: slot.batt_time_to_full.readable,
                firmware_version: akg_base_data.Device.fw_ver.readable,
                model: akg_base_data.Device.model.readable
              });
            });

            if (!has_cols) {
              // Determine column headers based on microphone properties
              const room_keys = Object.keys(mics[0]);

              room_keys.forEach((key) => {
                let title  = key.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

                mic_cols.push({
                  Header: title,
                  accessor: key
                });
              });

              has_cols = true;
            }
          }
        });

        this.setState((prev) => {
          return {
            mics: mics, ...prev.mics,
            mic_cols: mic_cols, ...prev.mic_cols,
            data_recived: true
          }
        });
    });
  }

  componentWillUnmount() {
    window.g_socket.removeAllListeners('rooms_mic_data');
  }

  render() {
    const { mics, mic_cols, data_recived } = this.state;

    console.log(mic_cols)

    if (!data_recived) {
      return (
        <div className='rooms-container'>
          <h2>Loading...</h2>
        </div>
      );
    }

    return (
      <div className='rooms-container'>
        <MicTable mics={mics} mic_cols={mic_cols}/>
      </div>
    );
  }
}

export default MicReport;