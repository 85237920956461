import React from 'react'
import { connect } from 'react-redux'
import { setReduxState, setModal } from '../../helperFunctions/actions'

// displays the Redux information stored in props.modal
// if (props.modal.shown), displays header, paragraph, buttonText
// On button press, runs onClose();
// If (cancelButton), displays a button to set props.modal to {shown:false}
function Modal(props) {
  if(!props.modal.shown) return null;
  //otherwise...
  
  const { header, paragraph, buttonText, onClose, cancelButton } = props.modal;
  function handleConfirm() {
    props.setModal({shown:false});
    onClose();
  }
  function handleCancel() {
    props.setReduxState( {modal: {shown:false} } );
  }
  return (
    <div className="modal">
      <div className="confirm-box-container fade-in">
        <div className="row center-xs col-xs-12">
          <h1>{header}</h1>
          <p>{paragraph}</p>
        </div>
        <div className="row box-contents">
          {cancelButton && <div className="col-xs-12 center-xs">
           <div className="button" onClick={handleCancel}>Cancel</div>
          </div>}
          <div className="col-xs-12 center-xs">
            <div className="button redbg" onClick={handleConfirm}>{buttonText}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ modal: state.modal });
const mapDispatchToActions = {setReduxState, setModal};
export default connect(mapStateToProps, mapDispatchToActions)(Modal);
