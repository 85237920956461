// given an array of arrays, return an intersection of the items in those arrays
export function intersect(filterArrays) {
  //intersect filters to find rooms that fit all
  let res = filterArrays[0]
  for(let i = 0; i < filterArrays.length; ++i) {
    res = res.filter( item => filterArrays[i].indexOf(item) !== -1 ); //filter items not in both
  }
  return res;
}

// FILTER: given the Room Status, Room Type, Building, and search parameters, return
// the intersection of all rooms matching those parameters ( array of room id's )
export function filter({ activeFilters, roomTypes, roomStatus, buildings, searchQuery, rooms }) {
  const filterArrays = [];
  let statusKey = activeFilters[0].replace(/ /g,'') //something like AllRooms (whitespace taken out for object key)
  filterArrays.push(roomStatus[statusKey])

  let buildingIndex = buildings.map(b => b.name).indexOf(activeFilters[2]);
  filterArrays.push(buildings[buildingIndex].rooms)

  let temp = [] //room type filter
  if(activeFilters[1][0] === "CoE Classroom") temp = temp.concat(roomTypes.CoEClassroom);
  if(activeFilters[1][1] === "CAEN Lab") temp = temp.concat(roomTypes.CAENLab);
  if(activeFilters[1][2] === "Conference Room") temp = temp.concat(roomTypes.ConferenceRoom);
  //change them all to numbers
  temp = temp.map(id => Number(id));
  filterArrays.push(temp);

  //search rooms for search query matches
  if(searchQuery !== "") {
    const searched = [];
    for(const id in rooms) {
      let matches = rooms[id].name.toLowerCase().includes(searchQuery.toLowerCase());
      if(matches) searched.push(Number(id));
    }
    filterArrays.push(searched)
  }

  //if a room comes first alphabetically, return -1. Else return 1
  const compareRooms = (a,b) => (rooms[a].name.toLowerCase() < rooms[b].name.toLowerCase() ? -1:1);
  //find intersection, sort it alphabetically
  let res = intersect(filterArrays).sort(compareRooms);
  return res;
}

//get the number badges for Room Types sidebar
export function getDisplayedRoomTypes({ activeFilters, roomTypes, roomStatus, buildings }) {
  let statusKey = activeFilters[0].replace(/ /g,''); //something like AllRooms (whitespace taken out for object key)
  let buildingIndex = buildings.map(b => b.name).indexOf(activeFilters[2]);

  const displayRoomTypes = {};
  for(const key in roomTypes) {
    let sum = 0;
    for(const id of roomTypes[key]) {
      if(buildings[buildingIndex].rooms.includes(id)
        && roomStatus[statusKey].includes(id)) sum++;
    }
    displayRoomTypes[key] = sum;
  }

  return displayRoomTypes;
}

export function getRoomTypes(rooms) {
  const roomTypes =  { CoEClassroom: [], CAENLab: [], ConferenceRoom: [] };

  for(const id in rooms) {
    let room = rooms[id];
    // 1 is classrooms, 2 is labs, 3 is conference rooms
    switch(room.room_type) {
      case 1: roomTypes.CoEClassroom.push(Number(id)); break;
      case 2: roomTypes.CAENLab.push(Number(id)); break;
      case 3: roomTypes.ConferenceRoom.push(Number(id)); break;
      default:
    }
  }
  return roomTypes;
}

// Builds the 'roomStatus' array for the filter and the sidebar display based on
//  whether the rooms have AV/user problems, aren't connected, have projectors on, etc.
export function getRoomStatus(rooms) {
  //reset the statuses
  const roomStatus = { AllRooms: [], NeedsAttention: [], ProjectorsOn: [], MicsNotCharged: [], CurrentlyRecording: [] }
  const roomIDs = Object.keys(rooms).map(id => Number(id)); // ["1":{...}, "3":{...}, "6":{...}] => [1,3,6]
  for (const id of roomIDs) {
    let room = rooms[id]
    //update roomStatus
    roomStatus.AllRooms.push(id);
    if( (room.user_problem > 0) || room.type === "av_issue" || (!room.connected) )
      roomStatus.NeedsAttention.push(id);
    if( (room.proj1_installed && room.proj1_pwr) || (room.proj2_installed && room.proj2_pwr) )
      roomStatus.ProjectorsOn.push(id)
    if( hasMicProblem(room) )
      roomStatus.MicsNotCharged.push(id)
    if( room.leccap_recording > 0 )
      roomStatus.CurrentlyRecording.push(id)
  }
  return roomStatus;
}

export function getDisplayedRoomStatus(roomStatus, roomTypes, activeFilters) {
  let output = {};
  const activeRoomTypes = activeFilters[1];
  for(const key in roomStatus) {
    let sum = 0;
    for(const roomid of roomStatus[key]) {
      for(let j = 0; j < activeFilters[1].length; j++) {
        if(activeRoomTypes[j] !== "") {
          sum += roomTypes[activeRoomTypes[j].replace(/ /g,'')].includes(roomid);
        }
      }
    }
    output[key] = sum;
  }
  return output;
}

// GIVEN an -array- of rooms, CALCULATE, sort, and return a 'buildings' array
// 'rooms' dictionary
export function formatRoomsArray(roomsArr) {
  //for each item of rooms, addRoom
  let roomDict = {};
  let buildingList = [];
  for(let i = 0; i < roomsArr.length; i++) {
    const room = roomsArr[i];
    //add to rooms dictionary with key room.id
    roomDict[room.id] = room;

    //check a list of building names for index of this room's building
    const indexOfBuilding = buildingList.map( b => b.name ).indexOf(room.building);
    if(indexOfBuilding >= 0) {
      //we have this room's building, increment the number of rooms inside it.
      buildingList[indexOfBuilding].rooms.push(room.id)
    } else {
      //we don't have this room's building yet
      buildingList.push( {name: room.building, rooms: [room.id]} ) //rooms list contains this id
    }
  }
  //Sort the buildings by name alphabetically
  buildingList.sort((a,b) => {
    return (a.name < b.name) ? -1:1 //default string comparison, then mapped to a positive or negative value
  })
  // Add an "All Buildings" category at the top
  buildingList.unshift({
    name:"All Buildings",
    rooms: buildingList.reduce((total, b) => [...total, ...b.rooms], []), //start at [] and add each building's rooms
  })

  return { rooms: roomDict, buildings: buildingList};
}

//THE BELOW FUNCTION was copied from the old angular CAENView

function hasMicProblem(room) {
  if(room.connected)
  {
    if(room.akg_base_installed){
          if(room.akg_connected && room.akg_base_data)
          {
            const roomData = JSON.parse(room.akg_base_data);
              let mics = 0;
              if (roomData && roomData.Device && roomData.Slots) {

                for (const slot of roomData.Slots) {
                  if (slot.batt_detected.raw === true) {
                    mics++;
                  }

                  if (slot.batt_state.readable !== 'Charged' && slot.batt_state.readable !== 'Empty') {
                    return true;
                  }
                }
              }

              return mics === 0 || mics !== roomData.Device.number_mics_expected; //if either of these are true, there's a problem
          }
            else
            {
              return true; //in status.ejs's old file, not connected --> mics = 0, micsExpected = -1 which would yield a problem
            }
        }
        else
        {
            return false; //no base, no problem
        }
  }
  else
  {
    return false; //if it's not even connected, don't bother
  }
}
