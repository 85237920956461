import React from 'react'
import CrestronRow from './CrestronRow'
import './MrsCrestronStyles.css'


class MrsCrestron extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      crestrons: [],
      sortType: "id",
      sortReversed: false
    }

    this.getCrestronById = this.getCrestronById.bind(this);
    this.sortList = this.sortList.bind(this);
    this.compareRows = this.compareRows.bind(this);
    this.updateCrestron = this.updateCrestron.bind(this);
    this.setSort = this.setSort.bind(this);
  }


  componentDidMount() {
    window.g_socket.emit('build_crestron_table');

    //listeners
    window.g_socket.on('one_crestron', (crestron) => {
      this.setState( (prev) => ({
          crestrons: [...prev.crestrons, crestron]
        })
      );
    });
  }
  componentWillUnmount() {
    window.g_socket.removeAllListeners('one_crestron');
  }


  getCrestronById(id) {
    const index = this.state.crestrons.map(c => c.id).indexOf(id);
    if(index === -1) return null;
    else return this.state.crestrons[index];
  }


  updateCrestron(cres) {
    this.setState( prev => {
      let newCrestrons = [...prev.crestrons];
      const index = newCrestrons.map(c => c.id).indexOf(cres.id);
      newCrestrons[index] = cres;
      return { crestrons: newCrestrons }
    } );
    this.sortList();
  }

  setSort(type) {
    this.setState( prev => {
      if(prev.sortType !== type) {
        return { sortReversed:false, sortType: type };
      } else {
        return { sortReversed: !prev.sortReversed, sortType: type };
      }
    });
    this.sortList();
  }

  sortList() {
    this.setState( prev => {
      let sortedCrestrons = [...prev.crestrons];
      sortedCrestrons.sort((a,b) => this.compareRows(a,b, prev.sortType, prev.sortReversed));

      return { crestrons: sortedCrestrons };
    });
  }

  compareRows(a,b, sortType, sortReversed) {
    let lhs, rhs;
    if(sortType === 'shortname') {
      //include the room number as well
      lhs = a.shortname + ' ' + a.room;
      rhs = b.shortname + ' ' + b.room;
    } else {
      lhs = a[sortType] || a.state[sortType];
      rhs = b[sortType] || b.state[sortType];
      if(lhs === undefined) return sortReversed ? -1:1;
      else if(rhs === undefined) return sortReversed ? 1:-1;
    }
    if(sortReversed) return lhs < rhs ? 1:-1;
    else return lhs < rhs ? -1:1;
  }


  render() {
    const { crestrons } = this.state;
    return (
      <div className="rooms-container">
        <div className="mrs-crestron">
        <h1>CAEN Crestron Emulator</h1>
        <table>
          <thead>
            <tr>
								<th>&nbsp;</th>
						    <th onClick={()=>this.setSort("id")}>ID</th>
								<th onClick={()=>this.setSort("shortname")}>Name</th>
								<th onClick={()=>this.setSort("port")}>Port</th>
								<th onClick={()=>this.setSort("philview_version")}>Version</th>
								<th onClick={()=>this.setSort("signal")}>Signal</th>
								<th onClick={()=>this.setSort("no_sig_sec")}>Time Since No Signal</th>
								<th onClick={()=>this.setSort("date")}>Date</th>
								<th onClick={()=>this.setSort("time")}>Time</th>
                <th onClick={()=>this.setSort("audio_healthy")}>Audio Healthy</th>
								<th onClick={()=>this.setSort("proj1_installed")}>Projector 1 Installed</th>
								<th onClick={()=>this.setSort("proj1_pwr")}>Projector 1 Power</th>
								<th onClick={()=>this.setSort("proj1_source")}>Projector 1 Source</th>
								<th onClick={()=>this.setSort("proj1_lamp_hours")}>Projector 1 Lamp Hours</th>
                <th onClick={()=>this.setSort("proj1_healthy")}>Projector 1 Healthy</th>
								<th onClick={()=>this.setSort("proj2_installed")}>Projector 2 Installed</th>
								<th onClick={()=>this.setSort("proj2_pwr")}>Projector 2 Power</th>
								<th onClick={()=>this.setSort("proj2_source")}>Projector 2 Source</th>
								<th onClick={()=>this.setSort("proj2_lamp_hours")}>Projector 2 Lamp Hours</th>
                <th onClick={()=>this.setSort("proj2_healthy")}>Projector 2 Healthy</th>
								<th onClick={()=>this.setSort("user_problem")}>User Problem</th>
								<th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            { crestrons.map((c) => <CrestronRow crestron={c} key={c.id} updateCrestron={this.updateCrestron}/>) }
          </tbody>
        </table>
        </div>
      </div>
    )
  }
}


export default MrsCrestron