import React, { useState, useEffect } from 'react'
import SidebarStatus from './SidebarStatus'
import SidebarRoomType from './SidebarRoomType'
import SidebarBuildings from './SidebarBuildings'
import { withRouter } from 'react-router-dom'
import { Collapse } from 'react-collapse'
import isMobile from '../../helperFunctions/isMobile'

function Sidebar(props) {
  const [expandFilters, setExpandFilters] = useState(false);
  const [wasMobile, setWasMobile] = useState(isMobile());
  useEffect(() => {
    function checkMobile() {
      if(isMobile() !== wasMobile) {
        //force update component by setting state
        // (so the sidebar shows correctly)
        setWasMobile(isMobile());
      }
    }
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [wasMobile]);
  if(props.location.pathname.includes('mrs-crestron')) return null;
  //if on mobile, render a filter button for expanding filters
  return (
    <div className="sidebar-container">  
        <div className="button col-xs-12 center-xs mobile-filter-button" onClick={() => setExpandFilters(prev => !prev)} style={{marginBottom:'20px'}}>
          <i className="fa fa-filter"></i>
          &nbsp;{expandFilters ? "Hide":"Show"} Filters
        </div>
      <Collapse isOpened={!isMobile() || expandFilters}>
        <SidebarStatus/>
        <SidebarRoomType/>
        <SidebarBuildings/>
      </Collapse>
    </div>
  )
}

export default withRouter(Sidebar);