import React from 'react'
import { connect } from 'react-redux'
import { filter, getRoomTypes } from '../../helperFunctions/filter'
import { updateActiveFilters, updateShownRooms } from '../../helperFunctions/actions'

function FilterWarningBar(props) {
  const { activeFilters, intersectedRooms } = props;
  const allBuildingsFilter = [activeFilters[0], activeFilters[1], "All Buildings"]

  function handleClick() {
    props.updateActiveFilters(allBuildingsFilter);
    props.updateShownRooms();
  }

  //don't show on 'all rooms' or 'all buildings' status
  if(activeFilters[0] === "All Rooms" || activeFilters[2] === "All Buildings") return null;
  //otherwise...
  
  //get a theoretical roomTypes list if we were filtering by all buildings
  const allBuildingsRoomTypes = getRoomTypes(props.rooms);
  //get a theoretical intersectedRooms list if we were filtering by all buildings
  const allBuildingsIntersectedRooms = filter({
    activeFilters: allBuildingsFilter, 
    roomTypes: allBuildingsRoomTypes,
    roomStatus: props.roomStatus, 
    buildings: props.buildings, 
    searchQuery: props.searchQuery, 
    rooms: props.rooms
  });
  
  let numOthers = allBuildingsIntersectedRooms.length - intersectedRooms.length;
  let plural = (numOthers !== 1);
  let msg = "";

  msg += "Filtering by "
  msg += activeFilters[2]; // current room status
  msg += ", there " + (plural ? "are ":"is ") + (numOthers || "no") + " other room" + (plural ? "s":"");
  if(numOthers) msg += " with this status";
  

  let btnText = "";
  btnText += "Show All Rooms ";
  switch (activeFilters[0]) {
    case "Needs Attention": btnText += "that Need Attention"
      break;
    case "Projectors On": btnText += "with Projectors On"
      break;
    case "Mics Not Charged": btnText += "with Mics Not Charged"
      break;
    case "Currently Recording": btnText += "that are Currently Recording"
      break;
    default: btnText += "ERROR: " + activeFilters[0] + " is not a room status"
  }

  //don't show if there are no other rooms
  if(!numOthers) return null;
  //otherwise...
  return (
    <div className="warning-bar">
      <div className="warning-container">
        <i className="fas fa-exclamation-circle"></i>
        <h4 className="notice-msg">{msg}</h4>
        {(numOthers > 0) && <div className="button notice-button" onClick={handleClick}>{btnText}</div>}
      </div>
    </div>
  )
}

function mapStateToProps(state) { return { 
    activeFilters: state.activeFilters, 
    roomTypes: state.roomTypes, 
    roomStatus: state.roomStatus, 
    buildings: state.buildings, 
    searchQuery: state.searchQuery, 
    rooms: state.rooms,
    intersectedRooms: state.intersectedRooms
  }
}
const mapDispatchToActions = { updateActiveFilters, updateShownRooms };
export default connect(mapStateToProps, mapDispatchToActions)(FilterWarningBar)