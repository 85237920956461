import React from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import WaitingScreen from './WaitingScreen'
import Modal from '../Reusable/Modal'
import Topbar from '../Bars/Topbar'
import Sidebar from '../Bars/Sidebar'
import RoomMenu from '../RoomMenu/RoomMenu'
import RoomInfo, { checkValidRoomInfoURL } from '../RoomInfo/RoomInfo'
import CalendarGrid from '../CalendarGrid/CalendarGrid'
import MicReport from '../MicReport/MicReport'
import MrsCrestron from '../MrsCrestron/MrsCrestron'
import ImageQuery from '../ImageQuery/ImageQuery'
import SystemSettings from '../SystemSettings/SystemSettings'
import WelcomePage from './WelcomePage'


function ProtectedRoutes(props) {
  if(props.isLoggedIn) {
    if(!props.dataReceived) {
      return <WaitingScreen text="Loading..." />
    } else {
      const modalGrayOut = props.modal.shown ? "gray-out":"";
      return (
        <>
          <Modal />
          <div id="confirm-box-root"></div>
          <div className={modalGrayOut}>
            <Topbar />
            <div className="main-container-flex" id="container-main">
              <Sidebar />
              <Switch>
                <Route exact path='/' component={RoomMenu} />
                {/* checkValidRoomInfoURL() to redirect back to home if it's an invalid room URL */}
                <Route path='/room' component={checkValidRoomInfoURL(RoomInfo)} />
                <Route exact path="/calendargrid" component={CalendarGrid} />
                <Route exact path="/micreport" component={MicReport} />
                <Route exact path="/mrs-crestron" component={MrsCrestron} />
                <Route path="/viewimages" component={ImageQuery} />
                <Route exact path="/settings" component={SystemSettings} />
                <Route><Redirect to="/" /></Route>
              </Switch>
            </div>
          </div>
        </>
      )
    }
  } else {
    return (
      <WelcomePage />
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    dataReceived: state.dataReceived,
    modal: state.modal
  }
}

export default withRouter(connect(mapStateToProps)(ProtectedRoutes));