import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

function LiveRoomCamera(props) {

  function openWebcam() {
    //open popup when room is clicked
    if (props.webcam) {
      var width = window.screen.availWidth;
      var height = window.screen.availHeight;
      window.open(props.webcam.webURL, 'Room Camera', 'menubar=1,resizable=1,width=' + width + ',height=' + height, 'left=0,top=0');
    }
  }
  //set height to 0 initially - on image load, height is set to image height
  const [iframeHeight, setIframeHeight] = useState('0px');
  
  //we'll attach a ref to our iframe to get the mounting node
  const [contentRef, setContentRef] = useState(null)
  const mountNode = contentRef && contentRef.contentWindow.document.body

  useEffect(() => {
    function resize() {
      if (!contentRef) {
        return;
      }
      const imgElt = contentRef.contentWindow.document.getElementById('webstream-image');
      if(!imgElt) {
        return;
      }
      setIframeHeight(imgElt.height + "px");
    }
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [contentRef]);

  if(!props.webcam?.liveURL) return null;

  return (
    <div className="webcamiframe">
      <div className="room-box">
        <div className="video-placeholder" style={{margin: "6px", height: iframeHeight}} >

          <iframe src="about:blank" title="Live Classroom Webstream" id="webstream" frameBorder="0" marginWidth="0" 
          scrolling="no" width="100%" height={iframeHeight} ref={setContentRef} style={{borderRadius:"5px"}}>
            {mountNode &&
              createPortal(
                React.Children.only(
                  <img 
                    id="webstream-image"
                    alt="Live room view"
                    style={{width:"100%", cursor:'pointer'}} 
                    src={`/room/${props.room.id}/camera/mjpeg`} 
                    onLoad={(e) => {
                      contentRef.contentWindow.document.body.style.margin = "0px";
                      setIframeHeight(e.target.height + "px");
                    }}
                    onClick={openWebcam}
                  />
                ),
                mountNode
              )}
          </iframe>

        </div>
      </div>
    </div>
  )
}

export default LiveRoomCamera