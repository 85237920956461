import React from 'react'
import ModernDatepicker from 'react-modern-datepicker'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import './ImageQueryStyles.css'
import SnapWindow from './SnapWindow'
import formatDate from '../../helperFunctions/formatDate'
import moment from 'moment'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'


const MAX_STORED_SNAPS = 720; // 2hrs at 6 snaps per minute

function RoomListOptions({rooms}) {
  return rooms.map( (room) => (
      <option key={room.id} value={room.name} />
    )
  )
}

class VideoImageQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roomSnaps: [],
      selectedDate: moment().format("MMMM DD, YYYY"),
      selectedTime: moment(new Date()),
      selectedRoom: "",
      rooms: [],
      minTime: null,
      maxTime: null,
      awaitingSnaps: {
        waiting: false,
        location: '',
        room: null
      },
      gotInitialSnaps: false,
      moreSnaps: false,
      errorMsg: "",
      timepickerOpen: false
    }

    this.requestInitialSnaps = this.requestInitialSnaps.bind(this);
    this.requestMoreSnaps = this.requestMoreSnaps.bind(this);
    this.handleGoButtonClick = this.handleGoButtonClick.bind(this);
    this.goToGridView = this.goToGridView.bind(this);
    this.datepickerChanged = this.datepickerChanged.bind(this);
  }

  componentDidMount() {

    window.g_socket.emit('get-image-roomlist');

    window.g_socket.on('ip-image-urls', (data) => {
      // data = { time, filename }

      //preload all images, add URLs to state
      let newSnaps = [];
      let starterKey;
      switch(this.state.awaitingSnaps.location) {
        case 'start': starterKey = this.state.roomSnaps[0].key - data.snapList.length; break;
        case 'end': starterKey = this.state.roomSnaps[this.state.roomSnaps.length - 1].key + 1; break;
        case 'initial': starterKey = 0; break;
        default:
      }
      for(let i = 0; i < data.snapList.length; i++) {
        const snap = data.snapList[i];
        const time = snap.time;
        const url = `https://s3.amazonaws.com/caenview-prod/${snap.filename}`
        const key = starterKey + i;
        // preload image
        new Image().src = url;
        newSnaps.push( {time, url, key} )
      }

      // we'll assign this to state later
      const newState = {};
      newState.errorMsg = "";

      //check if we're out of snaps
      if(newSnaps.length > 0) {
        //check if we've requested these snaps as an addition or initially
        if(this.state.awaitingSnaps.location !== 'initial') {
          // find out where we wanted to add the snaps (beginning or end)
          const oldSnaps = this.state.roomSnaps;
          if(this.state.awaitingSnaps.location === 'start') {

            if(oldSnaps.length + newSnaps.length > MAX_STORED_SNAPS) {
              newState.roomSnaps = newSnaps.concat(oldSnaps.slice(0, oldSnaps.length - newSnaps.length));
            } else {
              newState.roomSnaps = newSnaps.concat(oldSnaps);
            }

          } else if(this.state.awaitingSnaps.location === 'end') {

            if(oldSnaps.length + newSnaps.length > MAX_STORED_SNAPS) {
              newState.roomSnaps = oldSnaps.slice(newSnaps.length).concat(newSnaps);
            } else {
              newState.roomSnaps = oldSnaps.concat(newSnaps);
            }

          }

          newState.minTime = new Date(newState.roomSnaps[0].time);
          newState.maxTime = new Date(newState.roomSnaps[newState.roomSnaps.length - 1].time);


          
        } else {
          //initial grab
          newState.roomSnaps = newSnaps;
          newState.minTime = new Date( newSnaps[0].time );
          newState.maxTime = new Date( newSnaps[newSnaps.length - 1].time );
          newState.gotInitialSnaps = true;
        }

        
        newState.moreSnaps = true;
      } else {
        //we're out of snaps
        newState.moreSnaps = false;

        newState.errorMsg = "We couldn't find any " + (this.state.gotInitialSnaps ? "":"more ") + "snaps in that range.";

      }
      
      newState.awaitingSnaps = {waiting:false, location: '', room: this.state.awaitingSnaps.room};

      this.setState( newState );
    });

    window.g_socket.on('image-roomlist', (data) => {
      this.setState({rooms: data});

      if (this.props.location.state) {
        
        const { roomName, time } = this.props.location.state;

        if (data.some(room => (room.name === roomName)) && (time instanceof Date) && !isNaN(time.getTime())) {
          // valid room name and time
          this.setState({
            selectedRoom: roomName,
            selectedDate: moment(time).format("MMMM DD, YYYY"),
            selectedTime: moment(time)
          }, this.requestInitialSnaps);
        } else {
          console.error("Malformed location state to jump to time:", this.props.location.state);
        }
      }
    });

  }
  componentWillUnmount() {
    window.g_socket.removeAllListeners('ip-image-urls');
    window.g_socket.removeAllListeners('image-roomlist');
  }

  
  handleGoButtonClick() {

    this.requestInitialSnaps();

  }

  /*
  / make initial server request with inputted date, time, and room
  */
  requestInitialSnaps() {
    //check validity of request
    if(this.state.awaitingSnaps.waiting) {
      this.setState({errorMsg: 'Loading...'});
      return;
    } 
    const indexOfRoom = this.state.rooms.map(room => room.name).indexOf(this.state.selectedRoom);
    if(indexOfRoom === -1) {
      this.setState({errorMsg: "That's an invalid room."});
      return;
    } else if (!this.state.selectedDate || !this.state.selectedTime) {
      this.setState({errorMsg: "Please enter a time."});
      return;
    }

    this.setState({gotInitialSnaps: false});

    // initial image grab
    // get month, day from input
    let newTime = new Date(this.state.selectedDate);
    // add the hours/minutes time on
    const selectedTime = new Date(this.state.selectedTime);
    newTime.setHours(newTime.getHours() + selectedTime.getHours());
    newTime.setMinutes(newTime.getMinutes() + selectedTime.getMinutes());

    const MINUTES_PADDING = 30;
    window.g_socket.emit('get-ip-image-urls', {
      time: newTime,
      roomId: this.state.rooms[indexOfRoom].id,
      duration: MINUTES_PADDING * 60
    });
    this.setState( { awaitingSnaps: {
      waiting: true,
      location: 'initial',
      room: this.state.rooms[indexOfRoom].id
    }} );
  }

  /*
  / The server takes requests for a time and duration on either side of that time,
  / so we give it a time that is MINUTES_PADDING minutes in the past with MINUTES_PADDING 
  / duration on each side
  */
  requestMoreSnaps(location) {
    //check validity of request
    if(this.state.awaitingSnaps.waiting) {
      if(this.state.errorMsg !== "Loading...") this.setState({errorMsg: 'Loading...'});
      return;
    }

    const ONE_MINUTE = 60000; // milliseconds
    const MINUTES_PADDING = 5;
    let newTime;
    if(location === 'start') {
      newTime = new Date(this.state.minTime.getTime() - ONE_MINUTE * MINUTES_PADDING); // 5mins before minimum time, with 5mins padding
    } else if (location === 'end') {
      newTime = new Date(this.state.maxTime.getTime() + ONE_MINUTE * MINUTES_PADDING); // 5mins after maximum time, with 5mins padding
    }

    window.g_socket.emit('get-ip-image-urls', {
      time: newTime,
      roomId: this.state.awaitingSnaps.room,
      duration: MINUTES_PADDING * 60
    });
    this.setState( (prev) => ({ awaitingSnaps: {
      waiting: true,
      location: location,
      room: prev.awaitingSnaps.room
    }}) );
    
  }

  goToGridView() {
		// the View change button's tooltip needs to know to hide
		ReactTooltip.hide();
		this.props.history.push("/viewimages/grid");
	}

  datepickerChanged(val) {
    if (val !== "Invalid Date") {
      this.setState({selectedDate: val, timepickerOpen: true});
    }
  }


  render() {

    const { awaitingSnaps, rooms, roomSnaps, selectedRoom, selectedDate, selectedTime, gotInitialSnaps, minTime, maxTime, errorMsg } = this.state;
    //otherwise...
    return (
      <div className="rooms-container single-view">
        <div className="row between-xs">
          <div className="col-xs-3" id="left-column-imagequery">
            <div className="imagequery-top-box">
              {awaitingSnaps.room 
              ? <h1 className="imagequery-room-title">{rooms[rooms.map(room => room.id).indexOf(awaitingSnaps.room)].name}</h1>
              : <h1>View Room Snaps</h1>}
              {gotInitialSnaps && 
              <div>
                <h2>{formatDate(minTime)} to </h2><br />
                <h2>{formatDate(maxTime)}</h2>
              </div>}
              {rooms.length !== 0 && 
              <div>
                <input autoFocus className="text-input" type="text" list="imagequery-room-list" value={selectedRoom} onChange={e => this.setState( {selectedRoom: e.target.value} )}/>
                <datalist id="imagequery-room-list" >
                  <RoomListOptions rooms={rooms} />
                </datalist>
              </div>}
            </div>
            <div className="imagequery-middle-box">
              <h2>Date and Time:</h2>
              <div className="date-time-pickers-container">
                <div className="datepicker">
                  <ModernDatepicker
                    date={selectedDate}
                    onChange={this.datepickerChanged}
                    placeholder="Select a date..."
                    format="MMMM DD, YYYY"
                    primaryColor="var(--a-main-bg)"
                    secondaryColor="var(--main-bg)"
                    primaryTextColor="var(--info-text)"
                    secondaryTextColor="var(--a-info-text)"
                  />
                </div>
                <div className="timepicker">
                  <TimePicker 
                    value={selectedTime}
                    onChange={val => this.setState({selectedTime:val})}
                    open={this.state.timepickerOpen}
                    onOpen={() => this.setState({timepickerOpen: true})}
                    onClose={() => this.setState({timepickerOpen: false})}

                    showSecond={false}
                    format="h:mm a"
                    use12Hours
                    inputReadOnly
                    onAmPmChange={() => this.setState({timepickerOpen: false})}
                    />
                </div>
              </div>
              {(selectedDate && selectedTime && this.state.rooms.some(room => (room.name === selectedRoom)))
               && <div className="button imagequery-go-button fade-in" onClick={this.handleGoButtonClick}>Go!</div>}
              
              <div className="error-msg"><h2>{errorMsg}</h2></div>

            </div>

            <div className="imagequery-bottom-box">
              <h2>View:</h2>
              <div className="row view-container">
                <div
                  className="button col-sm center-xs active-view-button" 
                  data-tip="View snaps as a video feed"
                ><i className="fa fa-video"></i></div>
                &nbsp;
                <div
                  className="button col-sm center-xs" 
                  onClick={this.goToGridView}
                  data-tip="View snaps as a grid of snaps"
                ><i className="fa fa-th"></i></div>
              </div>
            </div>

          </div>
          <div className="col-xs-9" id="right-column">
            {gotInitialSnaps && <SnapWindow roomSnaps={roomSnaps} requestMoreSnaps={this.requestMoreSnaps} sliderConfig={{min: roomSnaps[0].key, max: roomSnaps[roomSnaps.length - 1].key}}/>}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(VideoImageQuery);