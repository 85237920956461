import React from 'react'

function WaitingScreen() {
  return (
    <div style={{padding:"20px"}}>
      <h2>Loading...</h2>
    </div>
  )
}

export default WaitingScreen;