import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createStore } from 'redux'
import rootReducer from './helperFunctions/rootReducer'
import { Provider } from 'react-redux'
import RenderTooltips from './components/Reusable/RenderTooltips'
import ProtectedRoutes from './components/Routing/ProtectedRoutes'
import SocketManager from './SocketManager'

export const rootStore = createStore(rootReducer);


class App extends React.Component {
  render() {
    return (
      <Router>
        <Provider store={rootStore}>
          <RenderTooltips />
          <SocketManager />
          <ProtectedRoutes />
        </Provider>
      </Router>
    )
  }
}

export default App