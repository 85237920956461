import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { setModal } from '../../helperFunctions/actions';
import VideoView from './VideoView';
import GridView from './GridView';


function ImageQuery(props) {
    const { match, canViewImagequery, setModal, history: { push } } = props;

    useEffect(() => {
        if(!canViewImagequery) {
            setModal({
              shown: true,
              header: "You're not authorized to view classroom snaps",
              paragraph: "If you think this is a mistake, please speak with CAEN administrative staff.",
              buttonText: "Back to CAENView",
              onClose: () => push('/')
            });
          }
    }, [canViewImagequery, setModal, push]);


    if (!canViewImagequery) {
        return null;
    } else {
        // match.path should be "/viewimages"
        return (
            <Switch>
                <Route exact path={`${match.path}/video`} component={VideoView} />
                <Route exact path={`${match.path}/grid`} component={GridView} />
                <Route>
                    <Redirect to={`${match.path}/video`} />
                </Route>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => ({ canViewImagequery: state.canViewImagequery });
const mapDispatchToActions = { setModal };
export default connect(mapStateToProps, mapDispatchToActions)(ImageQuery);