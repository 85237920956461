import React, { useLayoutEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isMobile from '../../helperFunctions/isMobile'

function RenderTooltips(props) {
  useLayoutEffect(() => {
    //after render, rebuild the tooltips so components that just mounted have tooltips.
    ReactTooltip.rebuild();
  }, [props])

  
  
  if(isMobile()) return null; //no tooltips on mobile devices
  return (
    <ReactTooltip place="bottom" effect="solid" multiline={true} textColor="#EFEFEF"/> 
  )
}

// when intersectedRooms changes, a hidden roombox might have shown
// connect() to it to watch it
// showImages shows a refresh button that might have been hidden, watch that too
const mapStateToProps = (state) => ({
  intersectedRooms: state.intersectedRooms,
  showImages: state.showImages
})
export default withRouter(connect(mapStateToProps)(RenderTooltips))