import React, { useEffect, useState } from 'react'

function SystemSettings() {
  const [settings, setSettings] = useState({});
  const [dataReceived, setDataReceived] = useState(false);
  useEffect(() => {
    window.g_socket.emit('get-settings');

    window.g_socket.on('settings', (data) => {
        setSettings(data);
        setDataReceived(true);
    });
    return () => window.g_socket.removeAllListeners('settings');
  }, []);

  function setSettingsDatabaseState(obj) {
    setSettings( prev => ({
      ...prev,
      db: {
        ...prev.db,
        ...obj
      }
    }) );
  }

  function handleChange(e) {
    setSettingsDatabaseState( { [e.target.name]:e.target.value } );
  }

  function saveSettings() {
    if (dataReceived) {
      setDataReceived(false);
      window.g_socket.emit('set-settings', settings.db);
    }
  }
  if(!dataReceived) return <h2>Loading...</h2>
  return (
    <div className="rooms-container">
      <div className="settings-group">
        <label htmlFor="staff_availText">Staffing Availability Text:</label>
        <input type="text" id="staff_availText" className="text-input" 
        name="staff_availText" value={settings.db.staff_availText || ""} onChange={handleChange}/>
        <p>Default if left empty: <strong>{settings.default.staff_availText}</strong></p>
      </div>

      <div className="settings-group">
        <label htmlFor="touchpanel_status_waiting">Status Text, Waiting for Acknowledgement:</label>
        <input type="text" id="touchpanel_status_waiting" className="text-input" 
        name="touchpanel_status_waiting" value={settings.db.touchpanel_status_waiting || ""} onChange={handleChange}/>
        <p>Default if left empty: <strong>{settings.default.touchpanel_status_waiting}</strong></p>
      </div>

      <div className="settings-group">
        <label htmlFor="touchpanel_status_immediate">Status Text, ASAP Response:</label>
        <input type="text" id="touchpanel_status_immediate" className="text-input" 
        name="touchpanel_status_immediate" value={settings.db.touchpanel_status_immediate || ""} onChange={handleChange}/>
        <p>Default if left empty: <strong>{settings.default.touchpanel_status_immediate}</strong></p>
      </div>

      <div className="settings-group">
        <label htmlFor="touchpanel_status_end_of_class">Status Text, at End of Class Response:</label>
        <input type="text" id="touchpanel_status_end_of_class" className="text-input" 
        name="touchpanel_status_end_of_class" value={settings.db.touchpanel_status_end_of_class || ""} onChange={handleChange}/>
        <p>Default if left empty: <strong>{settings.default.touchpanel_status_end_of_class}</strong></p>
      </div>

      <div className="row box-contents col-xs-12">
        <div className="button col-xs-12 center-xs" style={{maxWidth:'200px'}}  onClick={saveSettings}>
          Save Settings
        </div>
      </div>
      
    </div>
  )
}

export default SystemSettings
