import React from 'react'
import Cookies from 'universal-cookie'
import * as actions from './helperFunctions/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class SocketManager extends React.Component {
  componentDidMount() {
    if(!window.location.hostname.includes('itech-test') && !window.location.hostname.includes('localhost')) {
      window.g_socket = require('socket.io-client')('https://' + window.location.hostname + (window.location.port === 80 ? '' : ':' + window.location.port))
    } else {
      //for testing
      window.g_socket = require('socket.io-client')('https://itech-test.engin.umich.edu:8443');
    }
      
    const cookies = new Cookies()

    //---AUTHENTICATE ----
    this.props.setReduxState({errorObj: {message:"Waiting for a response from the server..."}})

    const urlParams = new URLSearchParams(window.location.search);
    const authKey = urlParams.get('authKey');

    window.g_socket.emit('authenticate', {
        origin:'web',
        authKey: authKey,
        token: (cookies.get('token', {doNotParse:true}) || null)
    })
    //the server parses the cookie on their side. Don't parse here.

    //--- REDIRECT TO OAUTH ----
    window.g_socket.on('redirect', (url) => {
      let d = new Date();
      d.setMinutes(d.getMinutes() + 2);
      cookies.addChangeListener( cookie => {
        //once the cookie as asynchronously changed...
        if(cookie.name === 'storedUrl') { 
          window.location.href = url; //then redirect
        }
      } );
      cookies.set('storedUrl',this.props.location.pathname, {expires: d, path:"/"});
      
    })

    window.g_socket.on('session-identified', (userInfo) => {
      this.props.login(userInfo);
      if(cookies.get('storedUrl')) {
        this.props.history.push(cookies.get('storedUrl'));
        cookies.remove('storedUrl', {path:'/'});
      }
    })

    window.g_socket.on('login-failed', payload => {
      // payload comes with two properties: message and option
      this.props.setReduxState({errorObj: payload});
    })
    
    window.g_socket.on('can-view-imagequery', () => {
      //give the user access to ImageQuery room snap browsing
      this.props.setReduxState({canViewImagequery: true});
    })

    // initiate redux room/building data
    window.g_socket.on('status_all', (rooms) => {
      this.props.addInitialRoomsArray(rooms);

      // check for initial problem rooms
      let probList = [];
      for(const room of rooms) {
        if(room.type === 'prob' && room.connected) {
          probList.push(room.id);
        }
      }

      if(probList.length > 0) this.props.setReduxState( {problemRooms:probList, ignoreNoticeBar: false } );
      this.props.updateRoomTypes();
      this.props.updateRoomStatus();
      this.props.updateShownRooms();
      this.props.setReduxState( {dataReceived:true} )
    })

    // update the status of a single room
    window.g_socket.on('status', (data) => {
      const { dataReceived, rooms, problemRooms } = this.props;

      if(dataReceived && rooms && rooms[data.id]) {
        //update that room with the new data
        this.props.updateRoom(data.json);
        this.props.updateRoomStatus();

        // check if it is or was a problem room
        if(problemRooms.includes(data.id)) {
          if(data.json.type !== 'prob'|| !data.json.connected) {
            //remove that room from problem rooms list
            const index = problemRooms.indexOf(data.id);
            let probCopy = [...problemRooms]
            probCopy.splice(index,1);
            this.props.setReduxState( {problemRooms: probCopy} );
          }
        } else if(data.json.type === 'prob' && data.json.connected) {
          this.props.setReduxState( { problemRooms: [ ...problemRooms, data.id], ignoreNoticeBar: false } );
        }
        
        this.props.updateShownRooms();
      } else {
        // we're not ready for this room
        if(dataReceived && rooms && !rooms[data.id]) {
          //specifically, we got some rooms, but not this one 
          console.error('rooms[' + data.id + '] has NOT been received');
        }
        return;
      }
      
    });


    window.g_socket.on('disconnect', () => {
        //scroll the user to the top of the page
        window.scrollTo(0,0);
        const modal =this.props.modal;
        if( !modal.shown ) {
          this.props.setModal({
            shown: true,
            header: "There's been a disconnect.",
            paragraph: "Reload to see new data",
            buttonText: "Reload",
            onClose: () => window.location.reload()
          });
        }
      }
    );
    
    window.g_socket.on('shutting_down', () => {
      this.props.killServer(false); //false to prevent it from emitting 'kill'
      //NOTE: this doesn't seem to work. Server issue - we just don't get the 'shutting_down' event before it shuts down
    });

  }

  componentWillUnmount() {
    window.g_socket.removeAllListeners('redirect')
    window.g_socket.removeAllListeners('session-identified')
    window.g_socket.removeAllListeners('login-failed')
    window.g_socket.removeAllListeners('can-view-imagequery');
    window.g_socket.removeAllListeners('status_all');
    window.g_socket.removeAllListeners('status');
    window.g_socket.removeAllListeners('disconnect');
    window.g_socket.removeAllListeners('shutting_down');
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return state
}
export default withRouter(connect(mapStateToProps, actions)(SocketManager))