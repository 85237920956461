import React from 'react'
import { Collapse } from 'react-collapse'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateActiveFilters, updateShownRooms, toggleCookie } from '../../helperFunctions/actions'


class SidebarBuildings extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilters = this.updateFilters.bind(this);
  }

  updateFilters(value) {
    const [statusFilter, typeFilter, buildingFilter] = this.props.activeFilters;
    if (value !== buildingFilter) {
      this.props.updateActiveFilters([statusFilter, typeFilter, value]);
      this.props.updateShownRooms();
    }

    //redirect to home if we're on another page (like roominfo)
    if(this.props.location.pathname !== '/') { 
      this.props.history.push('/') 
    }
  }

  

  render() {
    const categories = this.props.buildings.map(category => (
      <label className={(category.name === this.props.activeFilters[2]) ? "list-group-item selected":"list-group-item"} key={category.name}>
        <input type="radio" name="radio" checked={category.name === this.props.activeFilters[2]} onClick={() => this.updateFilters(category.name)} onChange={()=>{}}/>
        <span className="radiobtn"></span>
        <span className="list-item-name">{category.name}</span>
        <span className="list-item-number">{category.rooms.length}</span>
      </label>
    ));

    const chevron = (this.props.showBuildings) ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-up" />


    return (
      <div>
        <div className="sidebar-category">
          <ul>
            <li className="list-title accordion" onClick={() => this.props.toggleCookie("showBuildings")}>
              <h2>Building</h2>
              {chevron}
            </li>
            <Collapse isOpened={this.props.showBuildings}>
              {categories}
            </Collapse>
          </ul>
        </div>
        <p style={{fontSize:"12px"}}>Last modified: {this.props.lastModifiedDate}</p>
      </div>
    );
  }
}
  
function mapStateToProps(state) { return {
    activeFilters: state.activeFilters,
    buildings: state.buildings,
    showBuildings: state.showBuildings,
    lastModifiedDate: state.lastModifiedDate
  }
}
const mapDispatchToActions = { updateActiveFilters, updateShownRooms, toggleCookie };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(SidebarBuildings))