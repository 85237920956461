import React, { useState } from 'react';



export default function RoomNotes({ roomid, room_notes }) {

    const [roomNotesState, setRoomNotes] = useState({ edit: false, notes: room_notes ? room_notes : '' });

    const handleSaveButton = () => {
        var str = document.getElementById('roomNotes').value;
        if (str === roomNotesState.notes) {
            setRoomNotes({ ...roomNotesState, edit: false });
        } else {
            window.g_socket.emit('save_room_notes', { room_id: roomid, room_notes: str });
            setRoomNotes({ edit: false, notes: str });
        }
    };

    const handleEditButton = () => {
        setRoomNotes({ ...roomNotesState, edit: true });
    };

    return (
        <>
            <div className="room-notes">
                <div className="room-box">
                    <div className="row center-xs box-title greybg">
                        <div className="col-xs-12">
                            <h4>Room Notes</h4>
                        </div>
                    </div>
                    <div className="row box-contents">
                        {roomNotesState.edit ? (
                            <div className="col-xs-12 center-xs">
                                <form>
                                    <div className="form-group">
                                        <textarea className="form-control" id="roomNotes" rows="10" defaultValue={roomNotesState.notes}></textarea>
                                    </div>
                                    <div className="button" onClick={handleSaveButton}>Save</div>
                                </form>
                            </div>
                        ) : (
                            <div className="col-xs-12">
                                <div id="roomDiv">{roomNotesState.notes.split("\n").map((value, index) => {
                                    return <p key={index}>{value}</p>;
                                })}</div>
                                {roomNotesState.notes === "" ? (<div className="button center-xs" onClick={handleEditButton}>Add</div>) : (<div className="button center-xs" onClick={handleEditButton}>Edit</div>)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
