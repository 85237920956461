import React from 'react';
import ConfirmBox from '../Reusable/ConfirmBox'
import { connect } from 'react-redux'
import { sendCommand } from '../../helperFunctions/actions'

class Projectors extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nextCommand: "",
      proj1Loading: false,
      proj1LoadingTarget: "",
      proj2Loading: false,
      proj2LoadingTarget: "",
      selectedEl: null
    }
    this.sendCommand = this.sendCommand.bind(this);
    this.readyCommand = this.readyCommand.bind(this);
  }
  sendCommand() {
    //e.g. to turn projector 1 on, this.state.command = 'proj1_pwrOn'
    this.props.sendCommand(this.state.nextCommand, { id: this.props.room.id } );

    if(this.state.nextCommand.includes('proj1')) {
      this.setState( {proj1Loading:true, proj1LoadingTarget: this.state.nextCommand} );
    } else {
      this.setState( {proj2Loading:true, proj2LoadingTarget: this.state.nextCommand} );
    }
    this.setState({nextCommand:""});
  }

  readyCommand(whichProjector, e) {
    // set next command to correct projector, set up confirm bubble text
    let nextCommand = whichProjector;
    if(this.props.room[whichProjector + '_pwr']) {
      // e.g. proj1_pwr === true
      nextCommand = whichProjector + '_pwrOff';
    } else {
      // e.g. proj1_pwr === false
      nextCommand = whichProjector + '_pwrOn';
    }

    this.setState({nextCommand, selectedEl: e.currentTarget});
  }


  componentDidUpdate() {
    //check if we should turn off the spinner
    const { proj1Loading, proj1LoadingTarget, proj2Loading, proj2LoadingTarget } = this.state;
    const { proj1_pwr, proj2_pwr } = this.props.room;
    if(proj1Loading) {
      if((proj1LoadingTarget === 'proj1_pwrOff' && !proj1_pwr)
      || (proj1LoadingTarget === 'proj1_pwrOn' && proj1_pwr)) {
        this.setState( {proj1Loading:false} );
      }
    }
    if(proj2Loading) {
      if((proj2LoadingTarget === 'proj2_pwrOff' && !proj2_pwr) 
      || (proj2LoadingTarget === 'proj2_pwrOn' && proj2_pwr)) {
        this.setState( {proj2Loading:false} );
      }
    }
  }


  render() {
    const { proj1_pwr, proj2_pwr, proj1_installed, proj2_installed, connected } = this.props.room;
    const buttonClassName = connected ? "button" : "button-disabled";
    const { proj1Loading, proj2Loading } = this.state;
    const loadingIconStyle = { fontSize: '15px', marginTop:'0px' }
    if(!proj1_installed) return null;
    else return (
      <div className="projectors">
        <div className={connected ? "room-box" : "room-box box-disabled"}>
          <div className="row center-xs box-title greybg">
            <div className="col-xs-12">
              <h4>Projector{proj2_installed ? 's':''}</h4>
            </div>
          </div>
          <div className="row box-contents">
            <div className="col-xs-1 center-xs">
              <i className={proj1_pwr ? 'fa fa-power-off green':'fa fa-power-off grey'}></i>
            </div>
            <div className="col-xs-11 center-xs">
              <div className={buttonClassName} onClick = {(e) => this.readyCommand('proj1', e)}>
                Turn Projector 1 {proj1_pwr ? 'Off':'On'} 
                {proj1Loading && <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={loadingIconStyle}></i>}
              </div>
            </div>
          </div>
          {proj2_installed && <div className="row box-contents">
            <div className="col-xs-1 center-xs">
              <i className={proj2_pwr ? 'fa fa-power-off green':'fa fa-power-off grey'}></i>
            </div>
            <div className="col-xs-11 center-xs">
              <div className={buttonClassName} onClick = {(e) => this.readyCommand('proj2', e)} >
                Turn Projector 2 {proj2_pwr ? 'Off':'On'}
                {proj2Loading && <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={loadingIconStyle}></i>}
              </div>
            </div>
          </div>}
        </div>
        {this.state.nextCommand && 
        <ConfirmBox 
          onConfirm={this.sendCommand} 
          onCancel={() => this.setState({nextCommand:""})}
          targetEl={this.state.selectedEl}
        />}
      </div>
    );
  }

}


const mapActionsToProps = { sendCommand }
export default connect(null, mapActionsToProps)(Projectors)