import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateActiveFilters, updateShownRooms } from '../../helperFunctions/actions'

class SidebarStatus extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilters = this.updateFilters.bind(this);
  }
  
  updateFilters(value) {
    const [statusFilter, typeFilter, buildingFilter] = this.props.activeFilters;
    if (value !== statusFilter) {
      this.props.updateActiveFilters([value, typeFilter, buildingFilter]);
      this.props.updateShownRooms();
    }

    //redirect to home if we're on another page (like roominfo)
    if(this.props.location.pathname !== '/') { 
      this.props.history.push('/') 
    }
  }

  category(name) {
    // roomStatus.AllRooms is just roomStatus['All Rooms'] without the space
    let number = this.props.displayRoomStatus[name.replace(/ /g,'')];
    let needsAttnStyle = (name === "Needs Attention") ? {backgroundColor:"var(--red)", color:"white"}:{};
    return (
      <label className={(name === this.props.activeFilters[0]) ? "list-group-item selected":"list-group-item"} key={name}>
        <input type="radio" checked={name === this.props.activeFilters[0]} name="radio-room" onClick={() => this.updateFilters(name)} onChange={()=>{}}/>
        <span className="radiobtn"></span>
        <span className="list-item-name">{name}</span>
        <span className="list-item-number" style={needsAttnStyle}>{number}</span>
      </label>
    )
  }

  render() {

    const categories = [];
    categories.push(this.category("All Rooms"))
    categories.push(this.category("Needs Attention"))
    categories.push(this.category("Projectors On"))
    categories.push(this.category("Mics Not Charged"))
    categories.push(this.category("Currently Recording"))
        
    return (
      <div>
        <div className="sidebar-category">
          <ul>
            <li className="list-title">
              <h2>Room Status</h2>
            </li>
            {categories}
          </ul>
        </div>
      </div>
    );
  }
}
  
function mapStateToProps(state) { return {
    activeFilters: state.activeFilters,
    displayRoomStatus: state.displayRoomStatus
  }
}
const mapDispatchToActions = { updateActiveFilters, updateShownRooms };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(SidebarStatus))