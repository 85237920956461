import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

// wrap JSX in this element for a confirmation panel that
// triggers onConfirm when the Confirm button is pressed
// example: <ConfirmBox onConfirm={handleConfirm} onCancel={hideTheBox}> Are you sure? </ConfirmBox>
function ConfirmBox(props) {

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onCancel();
        }
      }
      
      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [ref]);
  }

  const containerRef = useRef(null);
  const {children, onConfirm, onCancel, buttonText = "Confirm", noButton = false, targetEl } = props;
  useOutsideAlerter(containerRef);
  const [ , setArbitraryState ] = useState(null);
  useEffect(() => {
    // setting state will force a rerender
    const updateComponent = () => setArbitraryState(Date.now());
    window.addEventListener('resize', updateComponent);
    return () => window.removeEventListener('resize', updateComponent);
  }, []);

  const styles = {};
  if(targetEl) {
    const rect = targetEl.getBoundingClientRect();
    styles.width = rect.width;
    styles.top = window.scrollY + rect.top + rect.height;
    styles.left = window.scrollX + rect.left;
    
  } else {
    throw new Error("No target element for Confirm Box");
  }


  return ReactDOM.createPortal(

    <div className="confirm-box-container fade-in arrow" ref={containerRef} style={styles}>
      <div className="row box-contents">
          {children}
        {!noButton && 
          <div className="col-xs-12 center-xs">
            <div className="button redbg" onClick={onConfirm}>{buttonText}</div>
          </div>
        }
      </div>
    </div>,

    document.getElementById('confirm-box-root')
  );
}

export default ConfirmBox