import React, { useState, useEffect, useCallback } from 'react'
import ReactSlider from 'react-slider'
import formatDate from '../../helperFunctions/formatDate'

const PLAYBACK_SPEED = 50; //ms 
const REQUEST_NEW_SNAPS_THRESHOLD = 30; //snaps


function SnapWindow( {roomSnaps, requestMoreSnaps, sliderConfig} ) {

  const [renderIndex, setRenderIndex] = useState(roomSnaps.length / 2);
  const [playForward, setPlayForward] = useState(false);
  const [playBackward, setPlayBackward] = useState(false);
  const [lastPlayDirection, setLastPlayDirection] = useState(1);

  const setTargetIndex = useCallback( (i) => {
    const minKey = sliderConfig.min;
    const maxKey = sliderConfig.max;
    if(i < minKey + REQUEST_NEW_SNAPS_THRESHOLD) {
      requestMoreSnaps('start');
      if(i < minKey) {
        return minKey;
      }
    } else if (i > maxKey - REQUEST_NEW_SNAPS_THRESHOLD) {
      requestMoreSnaps('end');
      if(i > maxKey) {
        return maxKey;
      }
    }
    return i;
  }, [sliderConfig, requestMoreSnaps]);

  useEffect(() => {
      function keyListener(e) {
        if(e.keyCode === 32 && e.target.nodeName !== "INPUT") {
          e.preventDefault();
          //toggle play button
          if(lastPlayDirection > 0) {
            setPlayForward(p => !p);
          } else {
            setPlayBackward(p => !p);
          }
        } else if (e.keyCode === 37) {
          setRenderIndex(i => setTargetIndex(i - 1));
        } else if (e.keyCode === 39) {
          setRenderIndex(i => setTargetIndex(i + 1));
        }
      }
      window.addEventListener('keydown', keyListener);
    return () => window.removeEventListener('keydown', keyListener);
  }, [lastPlayDirection, sliderConfig, setTargetIndex]);
  

  //run every time the play button pressed, clear when done
  useEffect( () => {
    if(playForward) {
      let interval = setInterval(() => setRenderIndex(i => setTargetIndex(i + 1)), PLAYBACK_SPEED);
      return () => clearInterval(interval);
    } else if(playBackward) {
      let interval = setInterval(() => setRenderIndex(i => setTargetIndex(i - 1)), PLAYBACK_SPEED);
      return () => clearInterval(interval);
    }
  }, [playForward, playBackward, sliderConfig, setTargetIndex]);
  function handleClick(cmd) {
    switch(cmd) {
      case 'playForward': 
        setPlayForward(true); 
        setPlayBackward(false);
        setLastPlayDirection(1);
        break;
      case 'playBackward': 
        setPlayBackward(true); 
        setPlayForward(false);
        setLastPlayDirection(-1);
        break;
      case 'pause': 
        setPlayForward(false); 
        setPlayBackward(false);
        break;
      case 'left':
        setRenderIndex(i => setTargetIndex(i - 1));
        break;
      case 'right':
        setRenderIndex(i => setTargetIndex(i + 1));
        break;
      default:
    }
  }

  let snap = roomSnaps[roomSnaps.map(snap => snap.key).indexOf(renderIndex)];
  if(snap === undefined) {
    //if it just deleted where we are now for efficiency, that would case a catastrophic error. Set index to something safe.
    // it should be updated on the next render by useEffect. We should fix this, but also, it's never really a problem.
    snap = roomSnaps[Math.floor(roomSnaps.length / 2)];
  }
  

  return (
    <div>
      <div className="room-box wide-box">
        <div className="imagequery-container">
          <img className="imagequery-jumbotron" alt="Room camera recording" src={snap.url}></img>
        </div>
      </div>

      <div style={{display: 'flex', justifyContent:'space-between'}}>
        <p>{formatDate(roomSnaps[0].time)}</p>
        <p>{formatDate(roomSnaps[roomSnaps.length - 1].time)}</p>
      </div>
      <ReactSlider 
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        min={sliderConfig.min}
        max={sliderConfig.max}
        withTracks={true}
        renderThumb={(props, state) => <div {...props}><div className="thumb-grab"></div></div>}

        value={renderIndex}
        onChange={val => setRenderIndex(setTargetIndex(val))}
      />
      <div className="thumb-date" style={{marginLeft: ((renderIndex - sliderConfig.min) / (sliderConfig.max - sliderConfig.min) * 75) + '%'}}>
        {formatDate(snap.time)}
      </div>
      <div className="buttons-container">
        <div className="button imagequery-control-button" onClick={() => handleClick('left')}><i className="fas fa-chevron-left"></i></div>
        {playBackward
          ? <div className="button imagequery-control-button" onClick={() => handleClick('pause')}><i className="fas fa-pause"></i></div>
          : <div className="button imagequery-control-button" onClick={() => handleClick('playBackward')}><i className="fas fa-play fa-flip-horizontal"></i></div>}
        {playForward
          ? <div className="button imagequery-control-button" onClick={() => handleClick('pause')}><i className="fas fa-pause"></i></div>
          : <div className="button imagequery-control-button" onClick={() => handleClick('playForward')}><i className="fas fa-play"></i></div>}
        <div className="button imagequery-control-button" onClick={() => handleClick('right')}><i className="fas fa-chevron-right"></i></div>
      </div>
    </div>
  )
}

export default SnapWindow
