import React from 'react';
import Roombox from './Roombox'
import FilterWarningBar from './FilterWarningBar'
import { connect } from 'react-redux';
import { setReduxState } from '../../helperFunctions/actions'
import ReactTooltip from 'react-tooltip';


function SearchDescription( {searchQuery, buildingFilter} ) {
  return (
    <div className="search-description">
      {searchQuery && <p>Showing results for <strong>{searchQuery.toUpperCase()}</strong>
      {(buildingFilter !== "All Buildings") && <span>, filtering by <strong>{buildingFilter}</strong></span>}
      </p>}
    </div>
  )
}

class RoomMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lastRefresh: new Date().getTime(),
      hasMounted: false
    }
    this.startCameraRefresh = this.startCameraRefresh.bind(this);
    this.updateCameras = this.updateCameras.bind(this);

    this.startCameraRefresh();
  }

  componentDidMount() {
    this.setState({hasMounted:true});
    //Once all roomboxes have mounted, build tooltips
    ReactTooltip.rebuild();
  }

  updateCameras() {
    // save the new Date.getTime() of our last refresh
    this.setState( {lastRefresh:new Date().getTime()} ) //changing state triggers rerender
    this.props.setReduxState( {spinRefreshIcon: true} );
    setTimeout(() => this.props.setReduxState( {spinRefreshIcon: false} ), 1000)
  }

  startCameraRefresh() {
    const REFRESH_SPEED = 15000; //15 sec
    this.refreshInterval = setInterval(() => {
      if(this.props.refreshImages) {
        this.updateCameras();
      }
    }, REFRESH_SPEED);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  componentDidUpdate(prevProps) {
    if(this.props.showImages === true && prevProps.showImages === false) {
      //user just showed images
      this.updateCameras();
    }
  }

  render() {
    let rooms = this.props.intersectedRooms.map(id => (
      <Roombox 
        roomid={id} 
        key={id} 
        imgSrc={(this.props.showImages && this.state.hasMounted)
          ? `/room/${id}/camera/jpeg?${this.state.lastRefresh}`
          : 'none'
        }/>
    ));

    return (
      <div style={{width:'100%'}}>
        <FilterWarningBar />
        <div className="rooms-container">
          <SearchDescription searchQuery={this.props.searchQuery} buildingFilter={this.props.activeFilters[2]} />
          <div className="rooms-display"> 
              {rooms}
          </div>
        </div>
      </div>
    );
  }
}

// watch only the props we need
function mapStateToProps(state) {
  return {
    intersectedRooms: state.intersectedRooms,
    showImages: state.showImages,
    refreshImages: state.refreshImages,
    searchQuery: state.searchQuery,
    activeFilters: state.activeFilters
  }
}

const mapDispatchToActions = { setReduxState };
export default React.memo(connect(mapStateToProps, mapDispatchToActions)(RoomMenu))