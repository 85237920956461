import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { setReduxState } from '../../helperFunctions/actions'
import { withRouter } from 'react-router-dom'

function NoticeBar(props) {
  const audioRef = useRef(null);
  useEffect( () => {
    //runs whenever problemRooms or ignoreNoticeBar changes
    if(!props.ignoreNoticeBar && props.problemRooms.length > 0) {
      function playSound() {
        if(audioRef.current && props.soundOn){ 
          audioRef.current.play().catch( (e) => {
            console.error('Cannot play ALERT sound until the page is clicked or interacted with in some way.');
          });
        }
      }
      //play every 10 seconds, and once initially
      playSound();
      let interval = setInterval(playSound, 10000);
      return () => clearInterval(interval);
    }
  }, [props.problemRooms, props.ignoreNoticeBar, audioRef, props.soundOn]);

  //don't display if there are no problem rooms
  if(props.problemRooms.length === 0 || props.ignoreNoticeBar) {
    return null;
  }
  
  function seeRoom(id) {
    props.history.push('/room/' + id);
  }
  function hide() {
    props.setReduxState( {ignoreNoticeBar: true} );
  }

  // construct the notice bar message
  const plural = (props.problemRooms.length !== 1);
  let msg = [];
  msg.push(<p key='problem'>{'There ' + (plural ? 'are':'is a') + ' new problem' + (plural ? 's':'') + ' in '}</p>);

  let lastItem = plural ? props.problemRooms[props.problemRooms.length - 1]:'';
  for(const id of props.problemRooms) {
    if(id === lastItem) msg.push(<p key='and'>&nbsp;and </p>);
    msg.push(<span key={id}><strong className="notice-bar-link" onClick={() => seeRoom(id)}>&nbsp;{props.rooms[id].name + ((id === lastItem || !plural) ? '':',')}</strong></span>);
  }

  return (
    <div className="notice-bar">
      <div className="notice-container">
        <i className="fas fa-exclamation-circle"></i>
        <h4 className="notice-msg">
          {msg}</h4>
        <div className="button notice-button" onClick={hide}>Ignore</div>
        <audio ref={audioRef} id="alert-sound"><source src="/nudge.mp3" type="audio/mpeg" /></audio>
      </div>
    </div>
  )
}

function mapStateToProps(state) { return {
    problemRooms: state.problemRooms,
    ignoreNoticeBar: state.ignoreNoticeBar,
    activeFilters: state.activeFilters,
    rooms: state.rooms,
    soundOn: state.soundOn
  }
}
export default withRouter(connect(mapStateToProps, {setReduxState})(NoticeBar));