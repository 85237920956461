import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateActiveFilters, updateShownRooms } from '../../helperFunctions/actions'


class SidebarRoomType extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilters = this.updateFilters.bind(this);
    this.category = this.category.bind(this);
  }
    
  // send Filters to props redux
  updateFilters(value) {
    const [statusFilter, typeFilter, buildingFilter] = this.props.activeFilters;
    // make a modifiable typeFilter copy
    let newTypeFilter = [...typeFilter];
    switch(value) {
      case "CoE Classroom": newTypeFilter[0] = (newTypeFilter[0] === "" ? "CoE Classroom":"");
        break;
      case "CAEN Lab": newTypeFilter[1] = (newTypeFilter[1] === "" ? "CAEN Lab":"");
        break; 
      case "Conference Room": newTypeFilter[2] = (newTypeFilter[2] === "" ? "Conference Room":"");
        break;
      default: console.error(value, ' is not a room type');
    }
    this.props.updateActiveFilters([statusFilter, newTypeFilter, buildingFilter]);
    this.props.updateShownRooms();

    //redirect to home if we're on another page (like roominfo)
    if(this.props.location.pathname !== '/') { 
      this.props.history.push('/') 
    }
  }

  category(name) {
    const { displayRoomTypes } = this.props;
    // displayRoomTypes.CoEClassroom is just displayRoomTypes['CoE Classroom'] without the ' '
    const number = displayRoomTypes[name.replace(/ /g,'')];
    return (
      <label className={this.props.activeFilters[1].includes(name) ? "list-group-item selected":"list-group-item"} key={name}> 
        <input 
          type="checkbox" 
          defaultChecked={this.props.activeFilters[1].includes(name)} 
          onChange={() => this.updateFilters(name)}
        />
        <span className="checkmark"></span>
        <span className="list-item-name">{name}</span>
        <span className="list-item-number">{number}</span>
      </label>
    )
  }


  render() {
    const categories = [];
    categories.push(this.category("CoE Classroom"));
    categories.push(this.category("CAEN Lab"));
    categories.push(this.category("Conference Room")); 

    return (
      <div>
        <div className="sidebar-category">
          <ul>
            <li className="list-title">
              <h2>Room Type</h2>
            </li>
            {categories}
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) { return {
    activeFilters: state.activeFilters,
    displayRoomTypes: state.displayRoomTypes,
    intersectedRooms: state.intersectedRooms
  }
}
const mapDispatchToActions = { updateActiveFilters, updateShownRooms };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(SidebarRoomType))