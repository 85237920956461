import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toggleCookie, logout, setModal, killServer } from '../../helperFunctions/actions'

function MenuSettings(props) {
  function confirmKillServer() {
    props.setModal({
      shown: true,
      header: "Restart the server?",
      paragraph: "It will take a minute to get back online.",
      buttonText: "Confirm",
      cancelButton: true,
      onClose: props.killServer
    });
  }

  return (
    <div className="settings fade-in" onClick={() => props.togglePopup('settings')}>
      <div className="row center-xs dropdown-title">
        <h4>Settings</h4>
      </div>
      <div className="row dropdown-contents"  onClick={(confirmKillServer)}>
      {/* Kill the server until AMAZON checks servers for a restart (every minute) */}
        <span>Restart CAENView</span>
      </div>
      <div className="row dropdown-contents" onClick={() => props.history.push('/settings')}>
        <span>System Settings</span>
      </div>
      <div className="row dropdown-contents"  onClick={() => props.toggleCookie("darkMode")}>
        <span>Switch to {props.darkMode ? "Light":"Dark"} Mode</span>
      </div>
      <div className="row dropdown-contents" onClick={() => props.history.push('/micreport')}>
        <span>Mic Report</span>
      </div>
      {props.canViewImagequery &&
      <div className="row dropdown-contents" onClick={() => props.history.push('/setcrestrontime')}>
        <span>Set Crestron Times</span>
      </div>}
      {props.testMode &&
      <div className="row dropdown-contents"  onClick={() => window.open("/mrs-crestron","_blank")}>
        <span>Crestron Emulator</span>
      </div>}
      {props.canViewImagequery &&
      <div className="row dropdown-contents"  onClick={() => props.history.push('/viewimages')}>
        <span>View Room Snaps</span>
      </div>}
      <div className="row dropdown-contents"  onClick={props.logout}>
        <span>Log Out</span>
      </div>
    </div>
  )
}

function mapStateToProps(state) { return {
  darkMode: state.darkMode,
  canViewImagequery: state.canViewImagequery,
  testMode: state.testMode
}};
const mapDispatchToActions = { toggleCookie, logout, setModal, killServer };
export default withRouter(connect(mapStateToProps,mapDispatchToActions)(MenuSettings))