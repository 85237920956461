/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'

function CrestronRow( {crestron: initCrestron, updateCrestron} ) {
  const [editing, setEditing] = useState(false);
  const [crestron, setCrestron] = useState(initCrestron);
  const [storedCrestron, setStoredCrestron] = useState(null);
  useEffect(() => {
    function update(data) {
      if(data.id === crestron.id) {
        setCrestron(data);
        updateCrestron(data);
      }
    }

    window.g_socket.on('crestron_updates', update);
    return () => window.g_socket.off('crestron_updates', update);
  }, [crestron, updateCrestron]);

  function storeData() {
    if(storedCrestron === null) setStoredCrestron(crestron);
  }


  function setCrestronState( changes ) {
    setCrestron( prevCrestron => ({
        ...prevCrestron,
        state: {
          ...prevCrestron.state,
          ...changes
        }
      }) );
  }


  function handleChange(e) {
    setEditing(true);
    const key = e.target.name;
    let value = e.target.value;
    if(typeof value === 'string') {
      if(!isNaN(value) && value !== "") value = Number(value);
    }
    // [key]:value -> something like ->  "philview_version": 6
    setCrestronState( { [key]:value } );
  }


  function tryExitEditMode(deleteData) {
    let areEqual = true;
    for(const key in crestron.state) {
      if( crestron.state[key] !== storedCrestron.state[key] ){
        areEqual = false;
      }
    }
    if(areEqual) {
      setEditing(false);
      if(deleteData) {
        setStoredCrestron(null);
      }
    }
  }


  function startCrestron() {
    window.g_socket.emit('start_crestron', crestron.id);
  }


  function stopCrestron() {
    window.g_socket.emit('stop_crestron', crestron.id);
  }

  //If the user uninstalls a projector, don't keep its previous values
  function checkProj1Data(e) {
    if(e.target.value == 0)
    {
      setCrestron( prev => {
        let newState = {...prev.state};
        delete newState.proj1_pwr;
        delete newState.proj1_source;
        delete newState.proj1_lamp_hours;
        delete newState.proj1_healthy;
        return {
          ...prev,
          state: newState
        }
      });
    }
  }


  function checkProj2Data(e) {
    if(e.target.value == 0)
    {
      setCrestron( prev => {
        let newState = {...prev.state};
        delete newState.proj2_pwr;
        delete newState.proj2_source;
        delete newState.proj2_lamp_hours;
        delete newState.proj2_healthy;
        return {
          ...prev,
          state: newState
        }
      });
    }
  }


  function cancel() {
    //revert changes
    setCrestron(storedCrestron);
    setStoredCrestron(null);
    setEditing(false);
  }


  function save() {
		if(crestron !== null && crestron.connected == 1)
		{
			let toSend = crestron.state; //the server seems to want just the state info but it also needs the id
			toSend.id = crestron.id;
			window.g_socket.emit('update_crestron_state', toSend);
			setStoredCrestron(null);
		}
		else
		{
      //revert changes
      setCrestron(storedCrestron);
      setStoredCrestron(null);
			alert("Save failed!");
    }
    setEditing(false);
  }


  let rowClassName = '';
  if(crestron.state.user_problem === 1) {
    rowClassName = 'error';
  } else if (crestron.connected == 0) {
    rowClassName = 'errconn'
  }
  return (
    <tr id={`crestron${crestron.id}`} className={rowClassName}>
      <td>
        <div className="save-and-cancel-buttons">
          {editing && <span className="cancel-button" onClick={cancel}>Cancel</span>}
          {editing && <span className="save-button" onClick={save}>Save</span>}
        </div>
      </td>
      <td>{crestron.id}<input type="hidden" name="id" value={crestron.id} /></td>
      <td>{crestron.shortname + ' ' + crestron.room}</td>
      <td>{crestron.port}</td>
      <td><input className="crestron-input input-small" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="philview_version" value={crestron.state.philview_version}>
      </input></td>
      <td><select className="crestron-input dropdown" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="signal" value={crestron.state.signal}>
        <option value={1}>Yes</option>
        <option value={0}>No</option>
      </select></td>
      <td><input className="crestron-input input-small" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="no_sig_sec" value={crestron.state.no_sig_sec}>
      </input></td>
      <td><input className="crestron-input input-large" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="date" value={crestron.state.date}>
      </input></td>
      <td><input className="crestron-input input-large" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="time" value={crestron.state.time}>
      </input></td>
      <td><select className="crestron-input dropdown" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="audio_healthy" value={crestron.state.audio_healthy}>
        <option value={1}>Yes</option>
        <option value={0}>No</option>
      </select></td>

      <td><select className="crestron-input dropdown" onFocus={storeData} onChange={(e) => {handleChange(e);checkProj1Data(e)}} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj1_installed" value={crestron.state.proj1_installed}>
        <option value={1}>Yes</option>
        <option value={0}>No</option>
      </select></td>
      <td>{(crestron.state.proj1_installed == 1) &&
        <select className="crestron-input dropdown" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj1_pwr" value={crestron.state.proj1_pwr}>
          <option value={1}>On</option>
          <option value={0}>Off</option>
        </select>
      }</td>
      <td>{(crestron.state.proj1_installed == 1) &&
        <input className="crestron-input input-large" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj1_source" value={crestron.state.proj1_source}>
        </input>
      }</td>
      <td>{(crestron.state.proj1_installed == 1) &&
        <input className="crestron-input input-large" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj1_lamp_hours" value={crestron.state.proj1_lamp_hours}>
        </input>
      }</td>
      <td>{(crestron.state.proj1_installed == 1) &&
        <select className="crestron-input dropdown" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj1_healthy" value={crestron.state.proj1_healthy}>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </select>
      }</td>

      <td><select className="crestron-input dropdown" onFocus={storeData} onChange={(e) => {handleChange(e);checkProj2Data(e)}} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj2_installed" value={crestron.state.proj2_installed}>
        <option value={1}>Yes</option>
        <option value={0}>No</option>
      </select></td>
      <td>{(crestron.state.proj2_installed == 1) &&
        <select className="crestron-input dropdown" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj2_pwr" value={crestron.state.proj2_pwr}>
          <option value={1}>On</option>
          <option value={0}>Off</option>
        </select>
      }</td>
      <td>{(crestron.state.proj2_installed == 1) &&
        <input className="crestron-input input-large" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj2_source" value={crestron.state.proj2_source}>
        </input>
      }</td>
      <td>{(crestron.state.proj2_installed == 1) &&
        <input className="crestron-input input-large" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj2_lamp_hours" value={crestron.state.proj2_lamp_hours}>
        </input>
      }</td>
      <td>{(crestron.state.proj2_installed == 1) &&
        <select className="crestron-input dropdown" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="proj2_healthy" value={crestron.state.proj2_healthy}>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </select>
      }</td>

      <td><select className="crestron-input dropdown" onFocus={storeData} onChange={handleChange} onBlur={() => tryExitEditMode(true)} disabled={crestron.connected != 1} name="user_problem" value={crestron.state.user_problem}>
        <option value={1}>Problem</option>
        <option value={0}>-</option>
      </select></td>
      <td className="stopstart"><a className="btn btn-small" className={(crestron.connected == 1) ? 'btn-danger' : 'btn-success'} value={(crestron.connected == 1) ? 'stop' : 'start'} onClick={() => {if (crestron.connected == 1) stopCrestron(); else startCrestron()}}></a></td>
    </tr>
  )
}

export default CrestronRow