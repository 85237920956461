import React, { useState } from 'react'

function DisplayDevice({settings}) {
  if (settings.ipod) {
    return (
      <div>
        <img className="ss-ipod-border" src="../../css/ipod_border.png" alt="iPod screenshot border"/>
        <img className="ss-ipod-image" src={settings.src} alt="iPod screenshot"/>
      </div>
    )
  } else {
    return (
      <div>
        <img className="ss-border" src="../../css/ipad_border.png" alt="iPad screenshot border"/>
        <img className="ss-image" src={settings.src} alt="iPad screenshot"/>
      </div>
    )
  }
}

export function DisplayTimestamp(props) {

  let currentTime = Math.round((new Date()).getTime() / 1000);
  //time since last update, in seconds
  const [ updateDiff, setUpdateDiff ] = useState(currentTime - props.lastUpdate);
  //update 1 second in the future with new time difference
  setTimeout(() => setUpdateDiff(currentTime - props.lastUpdate), 1000);

  
  
  //format the output message
  let unit = "";
  let displayTime;
  if(updateDiff < 120) {
    displayTime = updateDiff;
    unit = "second"
  } else if (updateDiff < 7200) {
    displayTime = Math.round(updateDiff / 60);
    unit = "minute"
  } else {
    displayTime = Math.round(updateDiff / 3600);
    unit = "hour"
  }
  
  return (
    <div className="room-box">
      <div className="row box-contents">
        <div className="col-xs-2 center-xs">
          <div className={updateDiff < 60 ? 'ss-updated-container' : 'ss-not-updated-container'} >
            <p>Last updated {displayTime} {unit}{displayTime === 1 ? '':'s'} ago</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function IOSScreenshot(props) {
  

  let deviceDisplay = {};
  try {
    const data = JSON.parse(props.screenshot);
    //the base64 image data is stored in data.image
    deviceDisplay.src = 'data:image/jpeg;base64, ' + data.image;

    //record timestamp info
    deviceDisplay.lastUpdate = data.timestamp;
    
    if (data.image.length < 24000) {
      deviceDisplay.ipod = true;
    } else {
      deviceDisplay.ipod = false;
    }
    if (!props.room.connected) {
      throw new Error("Room not connected");
    }
  } catch (e) {
    console.error(e);
    return null;
  }
  return (
    <div className="ss-container" style={{margin: "0 0 10px"}}>
      <DisplayDevice settings={deviceDisplay} />
      <DisplayTimestamp lastUpdate={deviceDisplay.lastUpdate} />
    </div>
  )
}

export default IOSScreenshot
